<template>
  <default-layout>
    <div class="container">
      <static-page />
    </div>
  </default-layout>
</template>

<script>
const StaticPage = () => import('@/components/static-page/term-of-service');
import DefaultLayout from '@/layouts/default';

export default {
  async fetch({ store, route }) {
    const baseUrl = store.state.global.baseUrl;
    const title = 'Terms and Conditions Propfix.sg';
    const description =
      'Terms and Conditions Propfix.sg, enjoy the convenience of renting, buying, and selling property in Propfix platform with secure and flexible online transacttion. Propfix helps you rent, sell, and buy property from HDB, condo, landed house, retail, hdb shophouse, food&beverage space, medical space, office, co-working space, serviced office, general industrial, factory, workshop, warehouse, shophouse, and land lot.';
    const head = {
      title: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
        {
          hid: 'og-description',
          name: 'og:description',
          content: description,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: baseUrl + route.fullPath,
          id: 'canonical',
        },
      ],
    };
    store.commit('head/SET_CONTENT', head);
  },
  components: {
    StaticPage,
    DefaultLayout,
  },
};
</script>
